import { ANIMATIONS, DEFAULT_ANIMATION, POPUP_TARGET_DEVICE_TYPE, POPUP_TRIGGER_TYPE, SECTION_TYPES } from '@segunosoftware/popup-shared';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import ActivePopup from './ActivePopup';
import { useIsMobile } from './hooks/useIsMobile';
import Storage from './storage';

const POPUP_CLOSE_KEY_PREFIX = 'seguno_popup_closed_';
const POPUP_INVALIDATE_QUERY_PARAM = 'seguno_invalidate_popup';

const MILLIS_PER_DAY = 86400000;
const DEFAULT_REST_PERIOD = 7 * MILLIS_PER_DAY;

const storage = new Storage();

const path = window.location.pathname;
const queryParams = window.location.search;

function getCloseKey(popupId) {
	return `${POPUP_CLOSE_KEY_PREFIX}${popupId}`;
}

function pathMatches(paths) {
	function isPathMatch(p) {
		p = encodeURI(p);
		const [onlyPath] = p.split('?');
		if (onlyPath === path) {
			return true;
		}
		if (onlyPath === '/' && path === '') {
			return true;
		}
		if (onlyPath.endsWith('*')) {
			const pathWithoutGlob = onlyPath.substring(0, onlyPath.length - 1);
			if (path.startsWith(pathWithoutGlob)) {
				return true;
			}
		}
		return false;
	}

	function isQueryParamsMatch(p) {
		if (!URLSearchParams) {
			return true;
		}
		const [, params] = p.split('?');
		if (!params) {
			return true;
		}
		const currentParams = new URLSearchParams(queryParams);
		const pathParams = new URLSearchParams(params);
		for (const pair of pathParams) {
			if (currentParams.get(pair[0]) !== pair[1]) {
				return false;
			}
		}
		return true;
	}

	return paths.find(p => isPathMatch(p) && isQueryParamsMatch(p));
}

function isValidActiveDate(startTimestamp, endTimestamp) {
	const now = Date.now();
	const isValidStartDate = !startTimestamp || now >= startTimestamp;
	const isValidEndDate = !endTimestamp || now < endTimestamp;
	return isValidStartDate && isValidEndDate;
}

export function isActiveDate(popup) {
	return isValidActiveDate(popup.triggerConfig.activeStartDate, popup.triggerConfig.activeEndDate);
}

export function isTargetedLanguage(popup) {
	const targetedLanguages = popup.triggerConfig.targetedLanguages ?? [];
	if (Boolean(targetedLanguages.length)) {
		return targetedLanguages.includes(getStorefrontLocale());
	}
	return true;
}

function hasInvalidationQueryParam() {
	try {
		const params = new URLSearchParams(document.location.search);
		return params.get(POPUP_INVALIDATE_QUERY_PARAM) === 'true';
	} catch (e) {
		// Most likely this is an older browser that doesn't support URLSearchParams
	}
}

function isFieldFound(popup, fieldId) {
	const stages = popup.config.stages ?? [];
	const visibleFormSections = stages
		.map(stage => stage.sections ?? [])
		.flat()
		.filter(section => !section.hidden && section.type === SECTION_TYPES.FORM);
	return Boolean(visibleFormSections.find(section => (section.settings.fields ?? []).find(field => field.id === fieldId)));
}

function getStorefrontLocale() {
	return window.Shopify?.locale;
}

App.propTypes = {
	accountId: PropTypes.string.isRequired,
	popups: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			config: PropTypes.shape({
				theme: PropTypes.shape({
					animation: PropTypes.oneOf(Object.keys(ANIMATIONS)).isRequired
				}).isRequired
			}).isRequired,
			triggerConfig: PropTypes.shape({
				enabled: PropTypes.bool.isRequired,
				inclusionPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
				exclusionPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
				priority: PropTypes.number.isRequired,
				targetDeviceType: PropTypes.oneOf(Object.keys(POPUP_TARGET_DEVICE_TYPE)),
				activeStartDate: PropTypes.number,
				activeEndDate: PropTypes.number,
				viewsRequired: PropTypes.number,
				invalidateClosesBefore: PropTypes.number,
				restPeriod: PropTypes.number
			})
		})
	).isRequired,
	customer: PropTypes.object.isRequired
};

export default function App({ accountId, popups, customer }) {
	const [openPopup, setOpenPopup] = useState(null);
	const [activePopups, setActivePopups] = useState([]);
	const isMobile = useIsMobile();

	useEffect(() => {
		if (popups.length === 0) {
			return;
		}

		const validPopups = popups
			.filter(popup => !Boolean(popup.embedded))
			.filter(popup => popup.triggerConfig.enabled)
			.filter(popup => {
				const targetType = popup.triggerConfig.targetDeviceType ?? POPUP_TARGET_DEVICE_TYPE.ALL;
				return (
					targetType === POPUP_TARGET_DEVICE_TYPE.ALL ||
					(targetType === POPUP_TARGET_DEVICE_TYPE.MOBILE && isMobile) ||
					(targetType === POPUP_TARGET_DEVICE_TYPE.DESKTOP && !isMobile)
				);
			})
			.filter(popup => !pathMatches(popup.triggerConfig.exclusionPaths))
			.filter(popup => popup.triggerConfig.inclusionPaths.length === 0 || pathMatches(popup.triggerConfig.inclusionPaths))
			.filter(popup => isActiveDate(popup))
			.filter(popup => customer.getViewCount() > popup.triggerConfig.viewsRequired)
			.filter(popup => {
				const closeKey = getCloseKey(popup.id);
				const closeValue = storage.getItem(closeKey);
				const isClosed = closeValue !== null;
				if (!isClosed) {
					return true;
				}
				const closeTime = Number(closeValue);
				const timeSinceClosed = Date.now() - closeTime;
				const restPeriod = popup.triggerConfig.restPeriodMillis ?? DEFAULT_REST_PERIOD;
				const shouldInvalidateClose =
					hasInvalidationQueryParam() || closeTime < popup.triggerConfig.invalidateClosesBefore || timeSinceClosed >= restPeriod;
				if (shouldInvalidateClose) {
					storage.removeItem(closeKey);
				}
				return shouldInvalidateClose;
			})
			.filter(popup => {
				const hasEmailField = isFieldFound(popup, 'EMAIL');
				const hasPhoneField = isFieldFound(popup, 'PHONE');
				const isSubscribedForPopup =
					(hasEmailField && customer.isCustomerEmailSubscribed()) || (hasPhoneField && customer.isCustomerPhoneSubscribed());
				return !isSubscribedForPopup;
			})
			.filter(popup => isTargetedLanguage(popup))
			.sort((a, b) => a.triggerConfig.priority - b.triggerConfig.priority);

		if (validPopups.length > 0) {
			const active = [];
			const exitIntentPopup = validPopups.find(popup => popup.triggerConfig.triggerType === POPUP_TRIGGER_TYPE.EXIT);
			if (exitIntentPopup) {
				active.push(exitIntentPopup);
			}
			const popup = validPopups.find(popup => popup.triggerConfig.triggerType !== POPUP_TRIGGER_TYPE.EXIT);
			if (popup) {
				active.push(popup);
			}
			setActivePopups(active);
		}
	}, [popups, customer, isMobile]);

	const onClose = useCallback(
		id => {
			setOpenPopup(null);
			storage.setItem(getCloseKey(id), Date.now());
			// Give the popup enough time to perform its exit animation
			const closingPopup = activePopups.find(p => p.id === id);
			const animation = closingPopup.config.theme.animation ?? DEFAULT_ANIMATION;
			setTimeout(() => {
				setActivePopups(activePopups => activePopups.filter(p => p.id !== id));
			}, ANIMATIONS[animation].out_duration);
		},
		[activePopups]
	);

	return activePopups.map(popup => (
		<ActivePopup
			key={popup.id}
			accountId={accountId}
			popup={popup}
			onOpenPopup={setOpenPopup}
			onClosePopup={onClose}
			activePopupId={openPopup}
			customer={customer}
			locale={getStorefrontLocale()}
		/>
	));
}
